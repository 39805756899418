import React from "react"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import Button from "../../../ui-kit/Button/Button"

// Icons
import Ventels from "../../../icons/light/ventels2.svg"
import Culler from "../../../icons/light/culler.svg"

import VentelsDark from "../../../icons/dark/ventels2.svg"
import CullerDark from "../../../icons/dark/culler.svg"
import { useCurrentTheme } from "../../../utils/themeChanger"
import LazyHydrate from "react-lazy-hydration"
// Styles
import * as styles from "./ApplyToPartner.module.sass"

const Apply = ({ data, header, button }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <div className={styles.content}>
      <Container>
        <Row>
          <h1>{header}</h1>
          <div className={styles.cards}>
            <div className={styles.card}>
              <StyledCard>
                <div className={styles.inner}>
                  <LazyHydrate ssrOnly>
                    <h3>{data.firstBlock.title}</h3>
                    <div
                      className={styles.describe}
                      dangerouslySetInnerHTML={{
                        __html: data.firstBlock.description,
                      }}
                    />
                    <div className={styles.button}>
                      <Button link={data.firstBlock.link} external>
                        {button}
                      </Button>
                    </div>
                  </LazyHydrate>
                  <div className={styles.icon}>
                    <img
                      alt="Ventels"
                      src={isLightTheme ? Ventels : VentelsDark}
                    />
                  </div>
                </div>
              </StyledCard>
            </div>
            <div className={styles.card}>
              <StyledCard>
                <div className={styles.inner}>
                  <LazyHydrate ssrOnly>
                    <h3>{data.secondBlock.title}</h3>
                    <div
                      className={styles.describe}
                      dangerouslySetInnerHTML={{
                        __html: data.secondBlock.description,
                      }}
                    />
                    <div className={styles.button}>
                      <Button link={data.secondBlock.link} external>
                        {button}
                      </Button>
                    </div>
                  </LazyHydrate>
                  <div className={styles.icon}>
                    <img
                      alt="Culler"
                      src={isLightTheme ? Culler : CullerDark}
                    />
                  </div>
                </div>
              </StyledCard>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Apply
